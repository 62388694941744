@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Chapman-Regular';
	src: url('../public/fonts/ChapmanTest-Regular.otf') format('opentype');
}

@font-face {
	font-family: 'Chapman-Bold';
	src: url('../public/fonts/ChapmanTest-Bold.otf') format('opentype');
}

@font-face {
	font-family: 'Chapman-Medium';
	src: url('../public/fonts/ChapmanTest-Medium.otf') format('opentype');
}

html {
	scroll-behavior: smooth;
}

::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

::-webkit-scrollbar-thumb {
	background-color: #2c2b2a;
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

.logo {
	scale: 30%;
}